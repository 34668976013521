.center{ text-align: center; }
.clear{ clear: both !important; float: none !important; }

table td,
table th{ padding: 5px;; }

.match-table td,
.lineups td,
.match-score td{ padding: 5px; }

.match-table .team,
.lineups .team,
.match-score .team{
    width: 200px;
}
.match-table .stats.heading{
    font-weight: bold;
    width: 417px;
    text-align: center;
}

.match-table .stats.home,
.match-table .team.home,
.match-score .team.home,
.lineups .team.home{ text-align: right; }

.match-table .time,
.match-table .team-separator,
.match-score .time,
.lineups .time{ width: 57px; }

.match-table .score,
.match-score .score,
.lineups .score{ width: 20px; }

.match-score .separator,
.match-score .separator,
.lineups .separator{ width: 17px; }

.goal.away{ padding-left: 200px; }
.player{ float: left; }
@media screen and (max-width: 767px){
    body{
        font-size: $mobile_fontsize;
    }
    .panel-title{
        font-size: $mobile_fontsize;
    }
}
